import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import Api from '../api/Api';

const storageAuthKey = 'authState';
let refreshingToken = false;

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  mfaHash?: string;
  tokens?: AuthTokens;
  user?: User;
}

interface AuthTokens {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  retrievedOn?: number;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<any>;
  mFALogin: (mfaHash: string, mfaChallenge: string) => Promise<any>;
  logout: () => void;
  assureAuthStateToken: (authState?: AuthState) => Promise<string>;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface ValidateManagerInput {
  Email?: string,
  Password?: string,
  Hash?: string,
  Challenge?: string
}

interface ExtendSessionInput {
  Email: string,
  RefreshToken: string,
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    mfaHash?: string;
    tokens?: AuthTokens;
    user?: User;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  mfaHash: null,
  isInitialised: true,
  tokens: null,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, mfaHash, tokens, user } = action.payload;

      return {
        ...state,
        isInitialised: true,
        isAuthenticated,
        mfaHash,
        tokens,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  mFALogin: () => Promise.resolve(),
  logout: () => null,
  assureAuthStateToken: () => Promise.resolve(null)
});

/**
 * AuthProvider component that keeps the authenticated user state 
 * and and can do the authentication and refresh accessTokens
 */
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  /**
   * Login the user by authenticating with Azure Active Directory, 
   * using Azure Function ValidateManager
   * @param {string} email The username for authenticating the user
   * @param {string} password The password for authenticating the user
   */
  const login = async (email: string, password: string): Promise<any> => {
    return new Promise( async (resolve, reject) => {

      // validate the user by calling the ValidateManager Function with email and password
      const postInput: ValidateManagerInput = {
        Email: email,
        Password: password,
      };
      Api.postRequest(Api.VALIDATE_MANAGER, postInput)
      .then(response => {
        const { managerFound, hash, error } = response;

        // add the returned mfa hash to the auth state and return true, else return an error
        if (managerFound && hash != null) {
          const authState: AuthState = {
            isInitialised: true,
            isAuthenticated: false,
            mfaHash: hash,
            tokens: null,
            user: null,
          };
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: authState
          });
          resolve(managerFound);

        } else {
          if (error != null) {
            reject(error);
          } else {
            reject('Manager not found');
          }
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  };

  /**
   * Login the user by authenticating the second factor and retrieve 
   * the access tokens from Azure Active Directory, using Azure Function 
   * ValidateManager
   * @param {string} mfaHash The MFA hash identifying the MFA challenge
   * @param {string} mfaChallenge The MFA second factor challenge password
   */
  const mFALogin = async (mfaHash: string, mfaChallenge: string): Promise<any> => {
    return new Promise( async (resolve, reject) => {

      // validate user by calling the ValidateManager Function with hash and mfa password
      const postInput: ValidateManagerInput = {
        Hash: mfaHash,
        Challenge: mfaChallenge,
      };
      Api.postRequest(Api.VALIDATE_MANAGER, postInput)
      .then(response => {
        const { managerFound, managerValidated, user, tokens, error } = response;

        // add the returned tokens and user to the auth state and return true, else return an error
        if (managerFound && managerValidated && user != null && tokens != null) {
          tokens.retrievedOn = Date.now();
          const authState: AuthState = {
            isInitialised: true,
            isAuthenticated: true,
            mfaHash: null,
            tokens,
            user
          };
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: authState
          });
          setStorageObject(storageAuthKey, authState);
          resolve(managerValidated);

        } else {
          if (error != null) {
            reject(error);
          } else {
            reject('Manager not validated');
          }
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  };

  /**
   * Return the accessToken, and check if it is still valid. If not 
   * refresh it first using the refreshToken
   */
  const assureAuthStateToken = (authState?: AuthState): Promise<string> => {
    return new Promise( async (resolve, reject) => {
      const tmpState = authState ? authState : state;

      // check we authenticated before and have tokens
      if (tmpState.isAuthenticated && tmpState.tokens != null && tmpState.tokens.retrievedOn > 0) {
        let tokenRefreshed: boolean = false;
        let accessTokenExpired: boolean = false;
        let tokens: AuthTokens = tmpState.tokens;
        const now: number = Date.now();

        // time tokens saved less then now - expires_in
        // (means the access_token is expired)
        accessTokenExpired = tokens.retrievedOn <= (now - (tokens.expiresIn * 1000));

        // or, time tokens saved newer then now (with a 10s margin)
        // (means that the datetime of the device has been tampered with)
        accessTokenExpired = accessTokenExpired || (tokens.retrievedOn > (now + 10000));

        // if the tokens are expired, refresh them
        if (accessTokenExpired && !refreshingToken) {
          refreshingToken = true;
          refreshToken(tmpState.user.email, tmpState.tokens)
            .then(tokens => {
              refreshingToken = false;
      
              // update the authState object in local storage, dispatch it to 
              // the context state, and return accessToken
              tokens.retrievedOn = Date.now();
              tmpState.tokens = tokens;
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: tmpState
              });
              tokenRefreshed = true;
              setStorageObject(storageAuthKey, tmpState);
              resolve(tokens.accessToken);
            })
            .catch(error => {
              refreshingToken = false;

              // clear the authState and return an error
              clearAuthState();
              reject(error);
            });
    
        } else {

          // token not expired, return it
          resolve(tokens.accessToken);
        }

        // if the token was not refreshed, but we were given an authState from the 
        // local storage, dispatch it to the context state
        if (!tokenRefreshed && typeof authState != 'undefined') {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: tmpState
          });
        }
      }
    });
  }

  /**
   * Logout by clearing the tokens, user, and set isAuthenticated to false
   */
  const logout = () => {
    clearAuthState();
  };

  /**
   * Clear the authState remove it from local storage
   */
  const clearAuthState = () => {
    
    // reset the values of the auth state
    const authState: AuthState = {
      isInitialised: true,
      isAuthenticated: false,
      mfaHash: null,
      tokens: null,
      user: null
    };
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: authState
    });

    // remove the auth state from the session
    window.sessionStorage.removeItem(storageAuthKey);
  }

  /**
   * If authenticated, either in the authContext state or the local storage authState, 
   * whichever has the most recent tokens, check if accessToken has not expired yet 
   * (and if so, refresh the token and update the authState, both in the authContext and 
   * the local storage)
   */
  useEffect(() => {
    const initialise = async () => {
      const storedAuthState: AuthState = getStorageObject(storageAuthKey);

      // are we authenticated in the state authContext
      if (state && state.isAuthenticated) {

        // are we authenticated in the localStorage authContext
        if (storedAuthState && storedAuthState.isAuthenticated) {

          // check which state has the most recent tokens and use them to assure
          if (storedAuthState.tokens.retrievedOn > state.tokens.retrievedOn) {
            await assureAuthStateToken(storedAuthState);
          } else {
            await assureAuthStateToken();
          }
        } else {

          // if we are not authenticated in the localStorage we try to assure with the state
          await assureAuthStateToken();
        }
      } else {

        // if we are not authenticated in the state, try with the localStorage authState
        if (storedAuthState) {
          await assureAuthStateToken(storedAuthState);
        }
      }
    };
    initialise();
  });

  // return SplashScreen while AuthState not initialised yet
  if (!state.isInitialised) {
    return <SplashScreen />;
  }
  
  // once initialised return the provider
  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        mFALogin,
        logout,
        assureAuthStateToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Return the accessToken, and check if it is still valid. If not 
 * refresh it first using the refreshToken
 */
export const assureToken = (): Promise<string> => {
  return new Promise( async (resolve, reject) => {

    // get auth state from the local storage
    const tmpState: AuthState = getStorageObject(storageAuthKey);

      // check we authenticated before and have tokens
      if (tmpState.isAuthenticated && tmpState.tokens != null && tmpState.tokens.retrievedOn > 0) {
      let accessTokenExpired: boolean = false;
      let tokens: AuthTokens = tmpState.tokens;
      const now: number = Date.now();

      // time tokens saved less then now - expires_in
      // (means the access_token is expired)
      accessTokenExpired = tokens.retrievedOn <= (now - (tokens.expiresIn * 1000));

      // or, time tokens saved newer then now (with a 10s margin)
      // (means that the datetime of the device has been tampered with)
      accessTokenExpired = accessTokenExpired || (tokens.retrievedOn > (now + 10000));
      
      if (accessTokenExpired) {
        refreshToken(tmpState.user.email, tmpState.tokens)
          .then(tokens => {
    
            // update tokens in authState object, in local storage and return accessToken
            tokens.retrievedOn = Date.now();
            tmpState.tokens = tokens;
            setStorageObject(storageAuthKey, tmpState);
            resolve(tokens.accessToken);
          })
          .catch(error => {

            // failed to refresh tokens, remove from local storage
            window.sessionStorage.removeItem(storageAuthKey);
            reject(error);
          });
  
      } else {

        // token not expired, resolve token
        resolve(tokens.accessToken);
      }
    }
  });
}

/**
 * Get a new set of tokens from Azure Active Directory,
 * using ConnectManagementFunctionApp ExtendSession
 */
export const refreshToken = (email: string, tokens: AuthTokens): Promise<any> => {
  return new Promise((resolve, reject) => {
    
    // refresh the existing tokens using the ExtendSession Function with email 
    // and refresh token, and existing access token as Bearer authorization header
    const postInput: ExtendSessionInput = {
      Email: email,
      RefreshToken: tokens.refreshToken,
    };
    Api.securePostRequest(Api.EXTEND_SESSION, postInput, tokens.accessToken)
    .then(response => {
      const { managerFound, sessionExtended, tokens, error } = response;

      // if manager found and session extented return the retrieved tokens, or else return an error
      if (managerFound && sessionExtended && tokens != null) {
        resolve(tokens);
      } else {
        if (error != null) {
          reject(error);
        } else {
          reject('Tokens not refreshed');
        }
      }
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Stringify a given object and store it in the local 
 * storage under given key
 * @param {string} key The key of the object to store
 * @param {any} value The object to store
 */
const setStorageObject = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * Get a stringefied object from the local storage and 
 * return the parsed object
 * @param {string} key The key of the object in the session
 */
const getStorageObject = (key: string) => {
  var value = window.sessionStorage.getItem(key);
  return value && JSON.parse(value);
}

export default AuthContext;
