import axios, { AxiosRequestConfig, Canceler, Method } from 'axios';
import { assureToken } from '../contexts/ConnectAuthContext';

const DEV = process.env.NODE_ENV === 'development';
// const DEV = false;

const SCHEME          = DEV ? 'http' : 'https';
const HEADER_AUTH     = 'Bearer';

const METHOD_POST     = 'POST';
const METHOD_GET      = 'GET';
const METHOD_PATCH    = 'PATCH';
const METHOD_DELETE   = 'DELETE';

const HOST_PROD       = 'api.apps-connect.com';
const HOST_DEV        = process.env.REACT_APP_FUNCTION_APP_HOST_DEV ? process.env.REACT_APP_FUNCTION_APP_HOST_DEV : 'localhost:7074';
const HOST            = DEV ? HOST_DEV : HOST_PROD;

const HOST_CONNECT_MANAGEMENT   = `${HOST}/management`;
const HOST_CONNECT_PUBLIC       = `${HOST}/public`;

const ENDPOINT_CONNECT_MANAGEMENT   = `${SCHEME}://${HOST_CONNECT_MANAGEMENT}/`;
const ENDPOINT_CONNECT_PUBLIC       = `${SCHEME}://${HOST_CONNECT_PUBLIC}/`;

class Api {

  /**
   * Public Azure Functions
   */
  static VALIDATE_MANAGER       = `${ENDPOINT_CONNECT_PUBLIC}ValidateManager?code=r6cA5vn1yetShZIr8dskdPdUvrxYEgmfeGLH8rSOheERW899wwypHw==`;

  /**
   * Secure Azure Functions
   */
  static EXTEND_SESSION                 = `${ENDPOINT_CONNECT_MANAGEMENT}ExtendSession`;
  static USER_FUNCTION                  = `${ENDPOINT_CONNECT_MANAGEMENT}UserFunction`;
  static DX_USER_FUNCTION               = `${ENDPOINT_CONNECT_MANAGEMENT}DXUserFunction`;
  static USERPASSWORD_FUNCTION          = `${ENDPOINT_CONNECT_MANAGEMENT}UserPasswordFunction`;
  static USERGROUP_FUNCTION             = `${ENDPOINT_CONNECT_MANAGEMENT}UsergroupFunction`;
  static ASSIGNMENT_FUNCTION            = `${ENDPOINT_CONNECT_MANAGEMENT}AssignmentFunction`;
  static PROFESSION_FUNCTION            = `${ENDPOINT_CONNECT_MANAGEMENT}ProfessionFunction`;
  static PROFESSIONCATEGORY_FUNCTION    = `${ENDPOINT_CONNECT_MANAGEMENT}ProfessioncategoryFunction`;
  static USERMESSAGE_FUNCTION           = `${ENDPOINT_CONNECT_MANAGEMENT}UserMessageFunction`;
  static MESSAGE_FUNCTION               = `${ENDPOINT_CONNECT_MANAGEMENT}MessageFunction`;
  static MESSAGESTATUS_FUNCTION         = `${ENDPOINT_CONNECT_MANAGEMENT}MessageStatusFunction`;
  static LANGUAGE_FUNCTION              = `${ENDPOINT_CONNECT_MANAGEMENT}LanguageFunction`;
  static TRANSLATION_FUNCTION           = `${ENDPOINT_CONNECT_MANAGEMENT}TranslationFunction`;
  static ENTITY_FUNCTION                = `${ENDPOINT_CONNECT_MANAGEMENT}EntityFunction`;
  static NEIGHBORHOOD_FUNCTION          = `${ENDPOINT_CONNECT_MANAGEMENT}NeighborhoodFunction`;
  static COUNTRY_FUNCTION               = `${ENDPOINT_CONNECT_MANAGEMENT}CountryFunction`;

  /**
   * GET
   */
  static getRequest = (func: string, params: any = null): Promise<any> => {
    return request(func, METHOD_GET, params);
  }

  /**
   * POST
   */
  static postRequest = (func: string, body: any = null): Promise<any> => {
    return request(func, METHOD_POST, body);
  }

  /**
   * Secure GET
   */
  static secureGetRequest(func: string, params: any = null, token: string = null) {
    return secureRequest(func, METHOD_GET, params, token);
  }

  /**
   * Secure POST
   */
  static securePostRequest(func: string, body: any = null, token: string = null) {
    return secureRequest(func, METHOD_POST, body, token);
  }

  /**
   * Secure PATCH
   */
   static securePatchRequest(func: string, params: any = null, token: string = null) {
    return secureRequest(func, METHOD_PATCH, params, token);
  }

  /**
   * Secure DELETE
   */
  static secureDeleteRequest(func: string, body: any = null, token: string = null) {
    return secureRequest(func, METHOD_DELETE, body, token);
  }
}

/**
 * Call an HTTP Api method
 * @param {string} func The api method to be called
 * @param {string} method The method type
 * @param {object} data An optional data param containing an object for a body of 
 * POST and PUT requests or queryParams for GET and DELETE requests
 */
const request = (func: string, method: Method, data: any = null): Promise<any> => {
  return new Promise((resolve, reject) => {
    
    const cancelTimeout: number = 15 * 1000;
    const requestTimeout: number = 10 * 1000;
    const CancelToken = axios.CancelToken;
    let cancelRequest: Canceler;

    // create configuration for the request
    let config: AxiosRequestConfig = {};
    config.method = method;
    config.url = func;
    config.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    config.cancelToken = new CancelToken(function executor(c) {
      cancelRequest = c;
    });
    config.timeout = requestTimeout;

    // start a timer that will cancel the request if cancelTimeout reached
    const cancelTimer = setTimeout(() => {
      cancelRequest("Timeout");
    }, cancelTimeout);

    // if extra data given, either add it as body or queryparams
    if (data !== null) {
      if (method === METHOD_POST) {
        config.data = data;
      } else if (method === METHOD_GET) {
        config.params = data;
      }
    }

    // fetch the request
    axios(config)
    .then(response => {
      clearTimeout(cancelTimer)
      const OK = response.data.OK ? response.data.OK : response.data.ok ? response.data.ok : false
      if(OK || (response.status >= 200 && response.status < 400)) {
        resolve(response.data);
      } else {
        // Logging.info(`Api.publicRequest, endpoint: ${func}, status: ${response.status}`, response);
        console.log(`Api.publicRequest, endpoint: ${func}, status: ${response.status}`, response);
        reject(response);
      }
    })
    .catch(error => {
      clearTimeout(cancelTimer);
      // Logging.error(`Failed Api.publicRequest: ${func}`, error);
      console.error(`Failed Api.publicRequest: ${func}`, error);
      reject(`Request failed, ${error.message}`);
    });
  });
}

/**
 * Call an HTTP Api method with an access_token as a Bearer in the Authorization header
 * @param {string} func The api method to be called
 * @param {string} method The method type
 * @param {object} data An optional data param containing an object for a body of 
 * POST and PUT requests or queryParams for GET and DELETE requests
 */
const secureRequest = (func: string, method: Method, data: any = null, accessToken = null): Promise<any> => {
  return new Promise((resolve, reject) => {

    const cancelTimeout: number = 15 * 1000;
    const requestTimeout: number = 10 * 1000;
    const CancelToken = axios.CancelToken;
    let cancelRequest: Canceler;
    
    // use either, the passed in access_token, or get the stored token from the device
    new Promise((resolveAT, rejectAT) => {
      if (accessToken) {
        resolveAT(accessToken);
      } else {
        assureToken()
        .then((storedAccessToken) => {
          resolveAT(storedAccessToken);
        })
      }
    })
    .then((token) => {

      // create configuration for the request
      let config: AxiosRequestConfig = {};
      config.method = method;
      config.url = func;
      config.headers = {
        'Authorization': `${HEADER_AUTH} ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      config.cancelToken = new CancelToken(function executor(c) {
        cancelRequest = c;
      });
      config.timeout = requestTimeout;
  
      // start a timer that will cancel the request if cancelTimeout reached
      const cancelTimer = setTimeout(() => {
        cancelRequest("Timeout");
      }, cancelTimeout);
  
      // if extra data given, either add it as body or queryparams
      if (data !== null) {
        if (method === METHOD_GET) {
          config.params = data;
        } else if (method === METHOD_POST || method === METHOD_PATCH || method === METHOD_DELETE) {
          config.data = data;
        }
      }
  
      // fetch the request
      axios(config)
      .then(response => {
        clearTimeout(cancelTimer)
        const OK = response.data.OK ? response.data.OK : response.data.ok ? response.data.ok : false
        if(OK || (response.status >= 200 && response.status < 400)) {
          resolve(response.data);
        } else {
          // Logging.info(`Api.secureRequest, endpoint: ${func}, status: ${response.status}`, response);
          console.log(`Api.secureRequest, endpoint: ${func}, status: ${response.status}`, response);
          reject(response);
        }
      })
      .catch(error => {
        clearTimeout(cancelTimer);
        // Logging.error(`Failed Api.secureRequest: ${func}`, error);
        console.error(`Failed Api.secureRequest: ${func}`, error);
        reject(`Request failed, ${error.message}`);
      });
    });
  });
}

export default Api;
